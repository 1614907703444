import { createSlice } from '@reduxjs/toolkit';
import { getOS } from 'utils/hooks/useOS';

const mobileSlice = createSlice({
	name: 'mobile',
	initialState: getOS() === 'android' || getOS() === 'ios',
	reducers: {
		setMobileMode: () => true,
		setDesktopMode: () => false,
		toggleMobileMode: state => !state,
	},
});

export const { setMobileMode, setDesktopMode, toggleMobileMode } =
	mobileSlice.actions;
export default mobileSlice.reducer;
